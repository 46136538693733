/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

jQuery.event.special.touchstart = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.touchmove = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.wheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("wheel", handle, { passive: true });
	}
};
jQuery.event.special.mousewheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("mousewheel", handle, { passive: true });
	}
};




(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				/*----------------------------------------------------------------------------------------------------
				Header Nav
				----------------------------------------------------------------------------------------------------*/

				var _header = $('.header'),
					_headerNav = _header.find('.header__nav'),
					_headerTrigger = _header.find('.header__trigger');


				_headerTrigger.on('click', function() {
					if(_headerTrigger.attr('aria-expanded') === 'true'){
						$('body').addClass('active-menu');
					} else {
						$('body').removeClass('active-menu');
					}
				});

				$('.header ul li a').on('click', function() {
					if(_headerTrigger.attr('aria-expanded') === 'true'){
						$('body').removeClass('active-menu');
						_header.removeClass('header--expanded');
						_headerNav.removeClass('header__nav--is-visible');
						_headerTrigger.attr('aria-expanded','false');
					}
				});
				
				/*----------------------------------------------------------------------------------------------------
				ScrollSpy
				----------------------------------------------------------------------------------------------------*/
				$('.scroll-spy').scrollSpy({
					scrollDuration: 800,
					offset: 0,
					scrollEasing: 'easeInBack',
					offsetElement: '.fixed__nav'
				});

				/*----------------------------------------------------------------------------------------------------
				Marquee
				----------------------------------------------------------------------------------------------------*/
				if ($.fn.marquee) {
					$(".marquee").each(function() {
						var _this 		= $(this),
							_tracker 	= _this.find('.tracker'),
							_direction 	= (_this.attr( 'data-direction') ) ? _this.data( 'direction' ) : 'left',
							_duration 	= (_this.attr('data-duration') ) ? _this.data( 'duration' ) : 15000;

						_tracker.marquee({
							duration: _duration,
							gap: 0,
							delayBeforeStart: 0,
							direction: _direction,
							duplicated: true
						});
					});
				}


				/*----------------------------------------------------------------------------------------------------
				Slider
				----------------------------------------------------------------------------------------------------*/
				if ($.fn.slick) {

					$('.testimonials__slider').slick({
						arrows: true,
						dots: false,
						pauseOnFocus: true,
						pauseOnHover:true,
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplay: false,
						autoplaySpeed: 4000,
						centerMode: true,
						centerPadding: '15%',
						adaptiveHeight: false,
						nextArrow: $('.slider__btn--next'),
						prevArrow: $('.slider__btn--prev'),
						responsive: [
							{
								breakpoint: 1024,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
									centerMode: true,
									centerPadding: '10%',
								}
							}, {
								breakpoint: 480,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
									centerMode: true,
									centerPadding: '30px'
								}
							}
						]
					});
				}

				/*----------------------------------------------------------------------------------------------------
				Hide Fixded Nav
				----------------------------------------------------------------------------------------------------*/
				var footerFixed   = $('.fixed__nav'),
					footerHeight  = footerFixed.height() + 100;

				$.fn.evaluate_scroll = function(){
					var currentTop = $(window).scrollTop();
				};

				//NAV FIXED

				
				$(window).on('scroll',{
					previousTop: 0
				}, 
				function () {
					var currentTop = $(window).scrollTop();
						//check if user is scrolling up
					if (currentTop < this.previousTop ) {
						//Footer :: if scrolling up...
						if (currentTop > footerHeight && footerFixed.hasClass('is-fixed')) {
							footerFixed.addClass('el-visible');
							footerFixed.removeClass('el-unpinned');
						} else {
							footerFixed.removeClass('el-visible el-fixed');
							footerFixed.addClass('el-unpinned');
						}

					} else {
						//if scrolling down...
						footerFixed.removeClass('el-visible');
						if( currentTop > footerHeight && !footerFixed.hasClass('el-fixed')) {
							footerFixed.addClass('el-fixed');
						}
					}
					this.previousTop = currentTop;
				});
				
				

				/*----------------------------------------------------------------------------------------------------
				Open Crispchat
				----------------------------------------------------------------------------------------------------*/
				$('.launch_chat').on('click', function() {
					$crisp.push(['do', 'chat:open']);
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
					// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};
	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
